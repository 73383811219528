import React from "react";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import sendIcon from "../../assets/send.svg";


const Newsletter = ({ email, setEmail, isSubscribed, setIsSubscribed, handleSubscribe }) => (
    <div className="share-container">
      <div className="share-icon">
        <img src={sendIcon} alt="Telegram" />
      </div>
      <div className="share-text">
        <div className="share-title">Weekly Newsletter</div>
        <div className="share-description">
          No spam. Just the latest releases and tips, interesting articles, and exclusive interviews in your inbox every week.
        </div>
      </div>
      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <div className="privacy-policy">
        Read about our <a href="/pages/privacy">privacy policy</a>
      </div>
      <ButtonComponent
        text={isSubscribed ? "Subscribed" : "Subscribe"}
        icon={false}
        coverFullWidth={true}
        handleClick={handleSubscribe}
        level={isSubscribed ? "disable" : "primary"}
      />
    </div>
  );

export default Newsletter;