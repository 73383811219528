import React, { useState, useEffect, useRef } from "react";
import { marked } from "marked";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./styles.scss";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import { useDispatch } from "react-redux";
import { notifySuccess } from "../../actions/global.action";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import sendIcon from "../../assets/send.svg";
import Newsletter from "./Newsletter";
import { parseFrontmatter, removeFrontmatter } from "../../utils/markdownUtils";

const formatPublishedDate = (dateStr) => {
  const [month, day, year] = dateStr.split("/");
  const date = new Date(year, month - 1, day);
  const monthName = date.toLocaleString("en-US", { month: "short" });
  return `${day} ${monthName} ${year}`;
};

const MdPage = ({ type }) => {
  const dispatch = useDispatch();
  const [metadata, setMetadata] = useState({
    category: "",
    minutes: "",
    title: "",
    description: "",
    image: "",
    published: "",
  });
  const [content, setContent] = useState("");
  const [notFound, setNotFound] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { filename } = useParams();
  const mdPageRef = useRef(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    const handleResize = () => {
      if (mdPageRef.current) {
        const { width } = mdPageRef.current.getBoundingClientRect();
        setIsMobile(width < 650);
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(handleResize);
    });

    if (mdPageRef.current) {
      resizeObserver.observe(mdPageRef.current);
    }

    handleResize();
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const renderer = new marked.Renderer();
    renderer.image = (href, title, text) => {
      return `<img src="${href}" alt="${text}" title="${title || ""}" class="content-image" />`;
    };
    marked.setOptions({ renderer });

    setNotFound(false);
    if (filename === "template") {
      setNotFound(true);
      return;
    }
    axios
      .get(`/md_pages/${type}/${filename}.md`)
      .then((response) => {
        const rawContent = response.data;
        setMetadata(parseFrontmatter(rawContent));
        setContent(marked(removeFrontmatter(rawContent)));
      })
      .catch((error) => {
        setNotFound(true);
        console.error("Error loading the page:", error);
      });
  }, [filename]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    dispatch(notifySuccess("Link copied to clipboard"));
  };

  const handleSubscribe = () => {
    // TODO: Implement subscription logic
    setIsSubscribed(true);
    dispatch(notifySuccess("You are subscribed to the newsletter"));
  };

  return notFound ? (
    <NotFoundPage />
  ) : (
    <div className={`md-page ${isMobile ? "mobile" : ""}`} ref={mdPageRef}>
      {metadata.title && (
        <div className="top">
          {isMobile && metadata.published && (
            <div className="metadata-row">
              <span className="published-date">
                <span className="published-date-text">
                  Published {formatPublishedDate(metadata.published)}
                </span>
              </span>
            </div>
          )}
          {!isMobile && metadata.category && metadata.minutes && (
            <div className="category-container">
              <span className="category">{metadata.category}</span>
              <span className="duration">{metadata.minutes} min read</span>
            </div>
          )}
          {metadata.title && <h1 className="title">{metadata.title}</h1>}
          {metadata.description && (
            <p className="description">{metadata.description}</p>
          )}
          {metadata.image && (
            <img src={metadata.image} alt={metadata.title} className="image" />
          )}
          {metadata.published && !isMobile && (
            <div className="metadata-row">
              <span className="published-date">
                <span className="published-date-text">Published on</span>
                <span className="published-date-value">
                  {formatPublishedDate(metadata.published)}
                </span>
              </span>
              <button className="copy-link-button" onClick={copyToClipboard}>
                <FontAwesomeIcon icon={faClone} />
                Copy link
              </button>
            </div>
          )}
        </div>
      )}
      <div className="bottom">
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {!isMobile && type === "blog" && (
          <Newsletter
            email={email}
            setEmail={setEmail}
            isSubscribed={isSubscribed}
            setIsSubscribed={setIsSubscribed}
            handleSubscribe={handleSubscribe}
          />
        )}
      </div>
    </div>
  );
};

export default MdPage;
