import { ApiService } from "./api.service.ts";

class VideoService {
  async getStaffPicks(): Promise<any> {
    return await ApiService.get("/videos/staff-picks/");
  }

  async getVideos(): Promise<any> {
    return await ApiService.get("/videos/");
  }

  async getVideo(id: string): Promise<any> {
    return await ApiService.get(`/video/${id}/`);
  }

  async getUserVideos(userId: string): Promise<any> {
    return await ApiService.get(`/user/${userId}/videos/`);
  }

  async addLike(id: string): Promise<any> {
    return await ApiService.post("/video/like/", { video_id: id });
  }

  async removeLike(id: string): Promise<any> {
    return await ApiService.post("/video/unlike/", { video_id: id });
  }

  async updateWatchProgress(
    video_id: string,
    progress: number,
    watchProgressId?: string,
  ) {
    return await ApiService.post(`/video/update-watch-progress/${video_id}/`, {
      progress,
      watch_progress_id: watchProgressId,
    });
  }

  async updateViews(
    video_id: string,
    progress: number,
    watchProgressId?: string,
  ) {
    return await ApiService.post(`/video/update-views/${video_id}/`, {
      progress,
      watch_progress_id: watchProgressId,
    });
  }

  async addToWatchHistory(videoId: string, playlistId: string): Promise<any> {
    return await ApiService.post("/video/watched/", {
      video_id: videoId,
      playlist_id: playlistId,
    });
  }

  async updateNote(data: any): Promise<any> {
    const { item_type, item_id, content } = data;
    return await ApiService.post("/notes/add/", {
      item_type,
      item_id,
      content,
    });
  }

  async deleteNote(noteId: string): Promise<any> {
    return await ApiService.delete(`/notes/delete/${noteId}/`);
  }

  async getPopular(card: boolean = false): Promise<any> {
    return await ApiService.get("/videos/popular/", {
      params: {
        card,
      },
    });
  }

  async getRecommended(videoId: string): Promise<any> {
    return await ApiService.get("/videos/recommended/", {
      params: {
        video_id: videoId,
      },
    });
  }

  async getCategories(featured: boolean = false): Promise<any> {
    return await ApiService.get("/video/categories/", {
      params: {
        featured,
      },
    });
  }

  async search(
    query_term: string,
    author_username: string | null = "null",
    search_category: string = "all",
    page: number = 0,
  ): Promise<any> {
    return await ApiService.get(
      `/search/?q=${query_term}&category=${search_category}&page=${page}&author=${author_username}`,
    );
  }

  async flagVideo(videoId: string, flagCategory: string) {
    return await ApiService.post(`/video/${videoId}/flag`, { flagCategory });
  }

  async deleteVideo(videoId: string) {
    return await ApiService.delete(`/videos/${videoId}/delete/`);
  }

  async changeVideoVisibility(videoId: string, visibility: string) {
    return await ApiService.post(`/video/change-visibility/${videoId}/`, {
      visibility,
    });
  }

  async preprocessVideo(data: any) {
    return await ApiService.post("/preprocess-video/", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async createVideo(data: any) {
    return await ApiService.put("/create-video/", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async editVideo(videoId: string, data: any) {
    return await ApiService.post(`/edit-video/${videoId}`, data);
  }

  async canWatchMoreVideos(): Promise<any> {
    return await ApiService.get("/videos/can-watch-more/");
  }

  async editVideoThumbnail(videoId: string, data: any) {
    return await ApiService.post(`/video/${videoId}/thumbnail`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async sortVideos(): Promise<any> {
    return await ApiService.get("/videos/sort");
  }
  async getVideosPaginated(page: number = 1): Promise<any> {
    return await ApiService.get(`/videos/retrieve/?page=${page}`);
  }
}

export const videoService = new VideoService();
